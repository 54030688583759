@font-face {
  font-family: "CJ Regular";
  src: url("./assets/font/CJ\ ONLYONE\ NEW\ body\ Regular.ttf");
}
html {
  font-size: 12px;
}
body {
  color: rgba(0, 0, 0, 0.85);
}
button,
input {
  color: inherit;
  font: inherit;
  border: 0;
}
a {
  text-decoration: none;
  color: black;
  display: inline;
}

.dates-container {
  color: black;
}

.date-picker-app-wrapper > .user-placeholder > .default-placeholder {
  border-radius: 0px;
  width: 20px;
  box-shadow: none;
  border: none;
  padding: 3px;
  border-bottom: 1px solid #949494;
}
.date-picker-app-wrapper > .user-placeholder > .default-placeholder > .text {
  color: #666666;
  font-size: 0.8rem;
}

#__range-picker-container > .visible > .full-date-picker-container {
  border-radius: 7px;
  padding: 20px;
  z-index: 10000;
}

#__range-picker-container
  > .visible
  > .full-date-picker-container
  :nth-child(1)
  > .date-picker
  > .grids {
  padding: 0;
}

.example-custom-input {
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9f9f9f;
}

.example-custom-input:hover {
  transition: all 0.05s;
  border-bottom: 2px solid black;
}
.rangePicker_filter {
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  color: #666666;
  cursor: pointer;
}
.react-datepicker__input-container {
  text-align: center;
}
.react-datepicker__input-container input {
  width: 100%;
  border: none;
  text-align: center;
}
.react-datepicker {
  padding: 10px;
  background-color: white;
  border-radius: 7px !important;
}
.react-datepicker
  > .react-datepicker__month-container
  > .react-datepicker__header {
  background-color: white;
  border: none;
}
.datepicker_btn {
  border-radius: 7px;
  padding: 3px;
}
.react-datepicker
  > .react-datepicker__month-container
  > .react-datepicker__header
  > .react-datepicker__day-names
  > div {
  font-weight: 700;
  font-size: 1rem;
}

.datepicker_updateGroup {
  border: 1px solid #8f939d;
  border-radius: 7px;
  padding-left: 12px;
  height: 32px;
}

.datepicker_createGroup {
  border: 1px solid #8f939d;
  border-radius: 7px;
  padding-left: 12px;
  height: 32px;
  width: 100%;
}

.datepickerInput {
  width: 200px;
  border: 1px solid #8f939d;
  border-radius: 7px;
  padding-left: 12px;
}
.datepickerInput::placeholder {
  font-size: 13px;
  color: #aeaeae;
}

.datepickerInput:hover {
  background-color: #eeeeeea2;
  transition: all 0.7s ease;
  /* border-bottom: 1px solid #565656; */
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.flatpickr-calendar
  .flatpickr-months
  .flatpickr-month
  .flatpickr-current-month
  > select {
  font-size: 13px;
  font-weight: 700;
}

.datepickerInput::placeholder {
  font-size: 0.9rem;
}
.MuiTextField-root > label {
  font-size: 0.9rem;
}
.MuiTextField-root > div > input::placeholder {
  font-size: 0.9rem;
}

.MuiOutlinedInput-root > div {
  font-size: 0.9rem;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150s linear;
}

.quill {
  border-radius: 7px;
  background: white;
}
.ql-editor {
  word-break: keep-all;
  resize: vertical;
  min-height: 150px;
}

.textQuill > div > div {
  padding: 25px;
}

.quill > .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 4px;
}

.quill > .ql-container {
  font-family: "CJ regular";
}
.quill > .ql-container.ql-snow {
  border: none;
}
.ql-container.ql-disabled .ql-tooltip {
  display: none;
}
.quill.ql-readOnly > div > div {
  padding: 0;
}

.roomTesterFocus {
  background: #f9e6aa;
}

.preExamCalendar {
  width: 100%;
}
.react-datetimerange-picker__wrapper {
  justify-content: space-between;
  width: 100%;
  border: none !important;
}
.react-datetimerange-picker__inputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ececec;
  background-color: white;
  flex-grow: unset !important;
  padding: 5px 14px !important;
  width: 42%;
}

.react-datetimerange-picker__inputGroup > select {
  -webkit-appearance: none; /* 크롬 화살표 없애기 */
  -moz-appearance: none; /* 파이어폭스 화살표 없애기 */
  appearance: none; /* 화살표 없애기 */
}

.react-datetimerange-picker__inputGroup__amPm {
  width: 30px;
  text-align: center;
}

.react-datetimerange-picker__inputGroup:hover {
  transition: all 0.4s;
  border: 1px solid #b7b7b7;
}
.react-datetimerange-picker__button {
  border: 1px solid #ececec !important;
  background-color: white !important;
  border-radius: 5px;
}
.react-datetimerange-picker__button:hover {
  transition: all 0.4s;
  background-color: #000000 !important;
  color: white;
}

.openCalendar {
  border-radius: 7px;
  padding: 15px;
  box-shadow: 7px 7px 10px #eee 10px 10px -10px #666666;
}
.react-calendar__tile--now {
  background: transparent !important;
  color: rgb(87, 87, 87) !important;
  font-weight: 700 !important;
}

.react-calendar__tile--now > abbr {
  width: 100%;
  display: block;
  border-bottom: 2px solid #6190ff;
}

.react-calendar__tile--active {
  background-color: #ddebff !important;
  color: rgb(75, 75, 75) !important;
}

.react-calendar__tile--hover {
  background: #ddebff !important;
  color: rgb(75, 75, 75) !important;
}

.react-calendar__tile--rangeStart {
  background-color: #b9d6ff !important;
  color: #0052cc !important;
  font-weight: 900 !important;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.react-calendar__tile--rangeEnd {
  background-color: #b9d6ff !important;
  color: #0052cc !important;
  font-weight: 900 !important;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.react-calendar button:hover {
  background-color: #c4ddff !important;
  color: #0052cc !important;
  font-weight: 700 !important;
  /* border-radius: 7px !important; */
  transition: all 0.3s ease-in-out;
}

.react-calendar__navigation button > span {
  font-weight: 700;
  font-size: 14px;
}
.react-datetimerange-picker__inputGroup__input {
  height: unset !important;
}
.react-calendar__month-view__weekdays__weekday--weekend:first-child {
  color: red !important;
}
.react-calendar__month-view__weekdays__weekday--weekend:last-child {
  color: blue !important;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.react-calendar__month-view__days__day--weekend:nth-child(7n) {
  color: blue;
}
