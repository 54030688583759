.questionText {
  word-break: keep-all;
}

.questionText > .ql-container * {
  margin: 0;
  font-size: 18px !important;
}

.questionText-readOnly {
  white-space: pre-wrap;
}
.questionText-readOnly > .ql-container > .ql-editor {
  padding: 0;
  resize: none;
  min-height: 0;
}
.questionText-readOnly > .ql-container * {
  margin: 0;
  font-size: 18px !important;
}

.questionSubText-readOnly.textQuill > .ql-container * {
  margin: 0;
  resize: none;
  min-height: 0;
}

.questionSubUseYN > .ql-container * {
  margin: 0;
}
.questionExText > .ql-container *,
.questionExText-readOnly > .ql-container * {
  margin: 0;
  font-size: 18px;
}

.questionExText-readOnly > .ql-container > .ql-editor {
  padding: 0 !important;
  overflow: unset;
}
.questionExText-readOnly > .ql-container > .ql-editor > p {
  font-size: 18px !important;
}
.questionSubText-readOnly.imgQuill > .ql-container > .ql-editor {
  padding: 0;
  resize: none;
  min-height: 0;
}
.questionSubText-readOnly.imgQuill > .ql-container > .ql-editor > p {
  line-height: 0;
}

.quill {
  border-radius: 7px;
}
.ql-editor {
  word-break: keep-all;
  line-height: 1.8;
}

.questionNo {
  font-size: 18px;
}

.ql-editing {
  left: 0 !important;
  z-index: 99;
}
.katex {
  font-family: "CJ Regular" !important;
  font: unset;
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="false"]::before {
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  font-size: 9px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  font-size: 11px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  font-size: 13px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
  font-size: 15px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before {
  font-size: 19px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before {
  font-size: 21px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before {
  font-size: 23px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  font-size: 24px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="false"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="false"]::before {
  content: "기본";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  content: "13px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
  content: "15px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before {
  content: "19px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before {
  content: "21px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before {
  content: "23px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}

.katex .vlist > span > .pstrut {
  /* height: 3.2rem !important; */
}

.ql-editor > p > * > u,
.ql-editor > p > u {
  text-underline-offset: 4px;
}
